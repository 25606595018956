<template>
  <section class="section section-hero">
    <div class="section-hero-bg">
      <SectionTitle
        icon="icon-services"
        h2="Webes szolgáltatások széles skálája <br class='d-none d-lg-block'> tervezhető határidőkkel és költségekkel"
        h2small="2021-ben az internetes jelenlét szükségszerű"
        createdate=""
      />
    </div>
    <div class="section section-hero-body section-hero-services">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-6">
            <small class="h2-small">
              Nincs olyan vállalkozás a világon, amelyet közvetve vagy
              közvetlenül ne érintene a COVID–19 elnevezésű betegséget okozó
              pandémia.
            </small>

            <div class="box-contents">
              <h3 class="mt-3">// Szolgáltatásaink</h3>
              <br />

              <router-link
                @click="hidden = !hidden"
                class="btn btn-link"
                to="/szolgaltatasaink/landing-oldal-keszites"
              >
                <span># Landing oldal (weboldal)</span>
              </router-link>

              <router-link
                @click="hidden = !hidden"
                class="btn btn-link"
                to="/szolgaltatasaink/statikus-es-dinamikus-banner-keszites"
              >
                <span># Statikus- és dinamikus Banner készítés</span>
              </router-link>

              <router-link
                @click="hidden = !hidden"
                class="btn btn-link"
                to="/szolgaltatasaink/support-szolgaltatas"
              >
                <span># Support szolgáltatás</span>
              </router-link>

              <!--              <router-link
                @click="hidden = !hidden"
                class="btn btn-link"
                to="#sitebuild-es-frontend-fejlesztes"
              >
                <span># Sitebuild és frontend fejlesztés</span>
              </router-link>

              <router-link
                @click="hidden = !hidden"
                class="btn btn-link"
                to="#webdesign-tervezes-keszites"
              >
                <span># Webdesign</span>
              </router-link>-->
            </div>
          </div>
          <div class="col-12 col-lg-6">
            <img
              src="@/assets/img/all/img-box-2021-left.png"
              alt=""
              class="img-fluid"
            />
          </div>
        </div>
      </div>
    </div>
  </section>

  <SectionBox
    id="landing-oldal-keszites"
    align="left"
    icon="icon-services"
    imgurl="img-box-services-landing-page-left"
    h2text="Évről-évre halogatta a döntést és így cégének még mindig nincs weboldala? <br class='d-none d-lg-block'> Új szolgáltatását vagy termékét kívánja megismertetni az interneten?"
    h2small="Landoljon minél több érdeklődő vállalkozásának termékeit vagy szolgáltatásait bemutató oldalán."
    ptext1=""
    createdate=""
    btntext="Ismerje meg a részleteket"
    btnurl="/szolgaltatasaink/landing-oldal-keszites"
    tag1="tanfolyam"
    tag1url=""
    tag2="frontend"
    tag2url="szolgaltatasaink/frontend"
    tag3="Vue.js 3"
    tag3url="szolgaltatasaink/frontend/vuejs"
  />

  <SectionBox
    id="statikus-es-dinamikus-banner-keszites"
    align="right"
    icon="icon-services"
    imgurl="img-box-services-banner-right"
    h2text="Látványos bannerekkel hirdetné cégét, termékét vagy szolgáltatását?"
    h2small="Rövid határidővel kreatívval vagy kreatív nélkül statikus- vagy dinamikus bannerek készítése cégeknek, ügynökségeknek weboldalakra, reklámkampányokhoz"
    ptext1=""
    createdate=""
    btntext="Ismerje meg a részleteket"
    btnurl="/szolgaltatasaink/statikus-es-dinamikus-banner-keszites"
    tag1="tanfolyam"
    tag1url=""
    tag2="frontend"
    tag2url="szolgaltatasaink/frontend"
    tag3="Vue.js 3"
    tag3url="szolgaltatasaink/frontend/vuejs"
  />

  <SectionBox
    id="support-szolgaltatas"
    align="left"
    icon="icon-services"
    imgurl="img-box-services-support-left"
    h2text="Weboldalán, közösségi oldalain a legfrissebb bejegyzések is több hónaposak? <br class='d-none d-lg-block'> Nincs ideje, energiája naprakészen tartani az információkat vagy újakat kitalálni? "
    h2small="Folyamatos online jelenléttel, kapcsolattartással fenntartható a már meglévő ügyfelek érdeklődése és az új látogatókból is könnyebben válik vásárló egy tartalmas, folyamatosan frissülő weboldallal."
    ptext1=""
    createdate=""
    btntext="Ismerje meg a részleteket"
    btnurl="/szolgaltatasaink/support-szolgaltatas"
    tag1="tanfolyam"
    tag1url=""
    tag2="frontend"
    tag2url="szolgaltatasaink/frontend"
    tag3="Vue.js 3"
    tag3url="szolgaltatasaink/frontend/vuejs"
  />

  <!--  <SectionBox
    id="sitebuild-es-frontend-fejlesztes"
    align="right"
    icon="icon-services"
    imgurl="img-box-services-sitebuild-right"
    h2text="Sitebuild és frontend fejlesztés <br class='d-none d-lg-block'> alvállalkozóként, csapatban"
    h2small="Ha nem akar cégében plusz erőforrást lekötni akkor projekt"
    ptext1=""
    createdate=""
    btntext="Ismerje meg a részleteket"
    btnurl="/szolgaltatasaink"
    tag1="tanfolyam"
    tag1url=""
    tag2="frontend"
    tag2url="szolgaltatasaink/frontend"
    tag3="Vue.js 3"
    tag3url="szolgaltatasaink/frontend/vuejs"
  />

  <SectionBox
    id="webdesign-tervezes-keszites"
    align="left"
    icon="icon-services"
    imgurl="img-box-services-design-left"
    h2text="Webdesign tervezés, készítés"
    h2small="2021-ben a talponmaradáshoz elengedhetetlen, hogy az interneten ismerjék cégét vagy szolgáltatását!"
    ptext1="Válassza landing oldal szolgáltatásunkat!"
    createdate=""
    btntext="Ismerje meg a részleteket"
    btnurl="/szolgaltatasaink/landing-oldal-keszites"
    tag1="tanfolyam"
    tag1url=""
    tag2="frontend"
    tag2url="/szolgaltatasaink/frontend"
    tag3="Vue.js 3"
    tag3url="szolgaltatasaink/frontend/vuejs"
  />-->
</template>

<script>
import SectionTitle from "@/components/SectionTitle.vue";
import SectionBox from "@/components/SectionBox.vue";

export default {
  components: {
    SectionTitle,
    SectionBox,
  },
  data() {
    return {};
  },
};
</script>
